import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage3Tooted from "./pages/Webpage3Tooted";
import Webpage4Ajurveeda from "./pages/Webpage4Ajurveeda";
import Webpage5KONTAKT from "./pages/Webpage5KONTAKT";
import Webpage6Konsultatsioon from "./pages/Webpage6Konsultatsioon";
import Webpage7Teraapiad from "./pages/Webpage7Teraapiad";
import Webpage8Toode from "./pages/Webpage8Toode";
import Webpage9Ajurveeda_soovitab from "./pages/Webpage9Ajurveeda_soovitab";
import Webpage10M____gitingimused from "./pages/Webpage10M____gitingimused";
import Webpage11Privaatsuspoliitika from "./pages/Webpage11Privaatsuspoliitika";
import Webpage12Soovitused_kevadeks from "./pages/Webpage12Soovitused_kevadeks";
import Webpage13Soovitused_s__giseks from "./pages/Webpage13Soovitused_s__giseks";
import Webpage14Soovitused_talveks from "./pages/Webpage14Soovitused_talveks";
import Webpage15Maksa from "./pages/Webpage15Maksa";
import Webpage16K__psised from "./pages/Webpage16K__psised";
import Webpage17Ajurveeda_kehat____bid from "./pages/Webpage17Ajurveeda_kehat____bid";
import Webpage18Not_found from "./pages/Webpage18Not_found";
import Webpage19Terapeudid from "./pages/Webpage19Terapeudid";
import Webpage20Soovitused_suveks from "./pages/Webpage20Soovitused_suveks";

var baseURL = 'https://my.prana.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiNzFkZmE2NjA3ZmQzMzg5NDFiN2M4ZWRiZDE1NzgzNGRhMTIwYmQzNzI0ZWMwYzAxMGI3OWY2OGI4NmZjYzFmYzdiNjY0YzE3YjVmNWUzZGMiLCJpYXQiOjE3NDM2MjEzNTcuMzQ4MDM1LCJuYmYiOjE3NDM2MjEzNTcuMzQ4MDQxLCJleHAiOjE3NDM3MDc3NTcuMzQyODc0LCJzdWIiOiI2MCIsInNjb3BlcyI6W119.faCU-Y-cfeXdmex7QmN2H6JTfF_29A-zfFB14nTO2jWKjEuTcliCLxzblAqg7Z0v1roF3IDcKyS7bc2R7eUxPIGU6UivjXhCixmU4_h2nhxWLNNUnDXFnvQlcdivOZvmS3A0cKRfyuzBGuSFzQq4FiA6sUCtDWfP9pXh7TVSfv-DvBlKudQKh8SbUvjio0hFRuSaFl1HL1C2rju7IMqV65UzX_ep6CftL6k6dB4gJ0DynybxNmqMIm8eNbTYn3a8EWRj2coG5JXtHEemlZYXHVHY4pkHE0nGFS0IdDHoRRAVlp4aZWsNg0jeO17eQ4ldfoKD7QUdQBhAmGxDusb2lg3Epi76HRJNSfb_XcML5h4E9I7GSkT25fXzUvvWXxvUFnbONgL7M4JXHVni4d2VMN6CPqvqCBz0LzYv-I_w0DnauZzFEdS098B05ZvxlCQvDIWjyWDN0QjbPosugfO3_LG8A97cXXG0yL0xRLYyIyhMuUynFfnA0eloUeMF5M9psUAt03L-sDxkhK_G0c1UekST_wwkXHT-9MnbW05zFqB1GkaiCzvK2N82Ha1Wq0XOt14AhXYEilUfBSUenX0KAjnnso5hxWU1i4k8s0bMSsVApxksHfBu0rxneeso3G5_xbHoufLAFwOL9bYgrrZI-qKoYeX5iCsKjTWCKUL6AkI',
    'Access-api-key': "eyJpdiI6IkY2a1dGQVBCc1g0TEZuckRjTWw1cHc9PSIsInZhbHVlIjoiOFVpT3NsVTh5Q05SNEp3MkZNTFBDZz09IiwibWFjIjoiM2YzYmY3MjBkNGJhODU0Y2YwMmM4OGM4MjNiN2JhZjk1OTI1ZjcwODAyMDNjMjE0ZDRlYmU3OGYxMzAwNTUxZCIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IkpnVXhhZHFOaGp4OEY0WXJZeS83cVE9PSIsInZhbHVlIjoiNStNZTV3aXlYRzE1REwwTEFLbXB3Zz09IiwibWFjIjoiMGE1M2QxYzM1N2EwOWJmYTE0YjgwYjdiYzk5ZGFmMWZlYjdlZTg4YzZmZmYwMmQwZmQ4ZWY3MzAyNjJlMzRjZSIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-NDMXWK5"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage3Tooted />} exact />;
                                                                    <Route path="/et/ayurveda" element={<Webpage4Ajurveeda />} exact />;
                                                                    <Route path="/et/contact" element={<Webpage5KONTAKT />} exact />;
                                                                    <Route path="/et/consultation" element={<Webpage6Konsultatsioon />} exact />;
                                                                    <Route path="/et/therapy" element={<Webpage7Teraapiad />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage8Toode />} exact />;
                                                                    <Route path="/et/ayurveda/recommend" element={<Webpage9Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/terms" element={<Webpage10M____gitingimused />} exact />;
                                                                    <Route path="/et/privacy" element={<Webpage11Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSpring" element={<Webpage12Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalAutumn" element={<Webpage13Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalWinter" element={<Webpage14Soovitused_talveks />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage15Maksa />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage16K__psised />} exact />;
                                                                    <Route path="/et/ayurveda/bodytypes" element={<Webpage17Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/et/*" element={<Webpage18Not_found />} exact />;
                                                <Route path="*" element={<Webpage18Not_found />} exact />;
                                                                    <Route path="/et/terapeudid" element={<Webpage19Terapeudid />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSummer" element={<Webpage20Soovitused_suveks />} exact />;
                                                                
                                            <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}